
// Libraries
import { getMaxTickSizes } from "@/operations/screensizes";
import { Chart, registerables, Tooltip } from "chart.js";
import { CrosshairPlugin } from "chartjs-plugin-crosshair";
import gradient from "chartjs-plugin-gradient";
import dayjs from "dayjs";
import { Component, Emit, Prop, Vue, Watch } from "nuxt-property-decorator";
import TooltipChart from "~/components/organisms/chart/TooltipChart.vue";
import { getTooltipDataFromContext, roundToNearest } from "~/operations/charts";

@Component({
  components: {
    TooltipChart,
  },
})
export default class LineChartOrganism extends Vue {
  @Prop({ required: true }) canvasID: string;
  @Prop() chartDescription: string;
  @Prop() chartType: "pricePrognosis" | "priceTrendHistory";
  @Prop() yAchsesLabel: string;
  @Prop() xAchsesLabel: string;
  @Prop({ default: 12 }) monthsQuantity: string;
  @Prop({ default: 12 }) axesFontSize: number;
  @Prop() widthQuery: number;
  @Prop() heightQuery: number;
  @Prop() chartDataSet: [
    {
      changedAtUtc: string;
      priceData: number;
      pricePrognosisDataTop: number;
      pricePrognosisDataBottom: number;
    },
  ];

  OilChartObject;
  labels: Array<string> = [];
  intersections: Array<number> = [];
  prognosisTop: Array<number> = [];
  prognosisBottom: Array<number> = [];
  currentYearsData: Array<number> = [];
  previousData1: Array<number> = [];
  previousData2: Array<number> = [];
  previousData3: Array<number> = [];
  min = 20;
  max = 125;
  tooltipData: object = {};
  PriceChartTooltip = "PriceChartTooltip";
  tooltipPosition: object = {
    xAlignleftS: 10,
    xAlignleftL: 45,
    top: 50,
    xAlignright: -45,
    breakpoint: 680,
    LeftBottom: 20,
    center: -95,
  };

  asd: any = "asd";

  setTooltipData(data) {
    this.tooltipData = data;
  }

  get getAxesFontSize() {
    return this.axesFontSize;
  }

  // 1 trigger
  externalTooltipHandler = (context) => {
    if (context.tooltip.body) {
      this.setTooltipData(getTooltipDataFromContext(context));
      const { chart, tooltip } = context;
      this.tooltipData = context;
    }
  };

  pricePrognosis = {
    type: "line",
    data: {
      labels: this.labels,
      Period: 0,
      datasets: [
        {
          label: "",
          data: this.intersections,
          borderColor: (context) =>
            this.setupChartMetas(context, "#000", "red"),
          borderWidth: (context) => this.setupChartMetas(context, 2, 5),
          pointRadius: (context) => this.setupChartMetas(context, 0, 2.28),
          pointHoverBackgroundColor: "#000",
          pointHoverBorderColor: "#fff",
          pointHoverBorderWidth: 3,
          pointHoverRadius: 6,
          fill: true,
          gradient: {
            backgroundColor: {
              axis: "y",
              colors: {
                0: "rgba(142, 142,142, 0.0)",
                500: "rgba(142, 142,142, 0.2)",
              },
            },
          },
        },
        {
          label: "",
          data: this.prognosisTop,
          borderColor: ["rgba(253, 200, 53, 0.5)"],
          borderWidth: +2,
          pointRadius: 0,
          pointHoverBackgroundColor: "transparent",
          pointHoverBorderColor: "transparent",
          pointHoverRadius: 0,
          backgroundColor: "rgba(253, 200, 53, 0.1)",
          fill: 2,
        },
        {
          label: "",
          data: this.prognosisBottom,
          borderColor: ["rgba(253, 200, 53, 0.5)"],
          borderWidth: 2,
          pointRadius: 0,
          pointHoverBackgroundColor: "transparent",
          pointHoverBorderColor: "transparent",
          pointHoverRadius: 0,
          backgroundColor: "transparent",
        },
      ],
    },
    options: {
      responsive: true,
      aspectRatio: ((context) =>
        window.innerWidth < 700 ? 1 | 0.2 : 0.5 | 2) as any,
      lineTension: 0.5,
      spanGaps: true,
      title: {
        display: false,
        text: "Heizölpreis-Entwicklung in Deutschland",
        fontSize: 22,
        position: "bottom" as const,
      },
      hover: {
        mode: "index",
        intersect: false,
      },
      scales: {
        y: {
          max: this.max,
          min: this.min,
          position: "right",
          ticks: {
            font: {
              size: this.getAxesFontSize,
            },
            beginAtZero: false,
            maxTicksLimit: 7, // wird beim rendern je nach Screensize neu gesetzt

            stepSize: 5,
            padding: 0,
            fontColor: "#1f2023",
            callback(value, index, values) {
              let Value = value.toFixed(0);
              return `${Value} €`;
            },
          },
          scaleLabel: {
            display: false,
            labelString: "€ / 100 L",
            fontColor: "#000000",
            fontSize: 12,
          },
          grid: {
            color: "rgba( 211, 211, 219, 0.4 )",
            drawBorder: false,
            drawOnChartArea: true,
            drawTicks: true,
            tickMarkLength: 10,
          },
        },
        x: {
          type: "category",
          distribution: "linear" as const,
          ticks: {
            font: {
              size: this.getAxesFontSize,
            },
            autoSkip: true,
            maxTicksLimit: 6, // wird beim rendern je nach Screensize neu gesetzt
            maxRotation: 0,
            beginAtZero: false,
            padding: 10,
            align: "center",

            callback: (value, index, values) => {
              const splittedDate =
                this.pricePrognosis.data.labels[index].split(".");
              const dateFormat =
                Number(this.monthsQuantity) >= 36 ? "YYYY" : "MMM";
              return dayjs(
                `${splittedDate[2]}-${splittedDate[1]}-${splittedDate[0]}`,
              ).format(dateFormat);
            },
          },
          scaleLabel: {
            display: false,
            labelString: "",
            fontColor: "#000000",
            fontSize: 12,
          },
          grid: {
            color: "rgba( 211, 211, 219, 0.4 )",
            zeroLineColor: "#cbd0d2",
            zeroLineBorderDash: [3, 9],
            drawBorder: true,
            drawOnChartArea: true,
            drawTicks: true,
            tickMarkLength: 10,
          },
        },
      },

      plugins: {
        legend: {
          display: false,
          position: "bottom" as const,
          align: "start" as const,
          labels: {
            fontColor: "rgba(0,0,0)",
            usePointStyle: true,
            boxWidth: 5,
            padding: 10,
          },
        },
        tooltip: {
          enabled: false,
          external: this.externalTooltipHandler,
          mode: "index",
          intersect: false,
          position: "custom",
          displayColors: false,
          xPadding: 20,
          yPadding: 20,
          caretPadding: 6,
          cornerRadius: 5,
          borderWidth: 1,
          bodySpacing: 20,
          borderColor: "#000",
          callbacks: {
            label: function (context) {
              var label = ` ${context.formattedValue}`;
              var datasetWithoutNull = context.dataset.data.filter(function (
                el,
              ) {
                return el != null;
              });

              if (context.datasetIndex === 0) {
                return [
                  `${label} € `,
                  `Heute: ${datasetWithoutNull[datasetWithoutNull.length - 1]
                    .toString()
                    .replace(".", ",")} € `,
                ];
              }
            },
          },
        },
        crosshair: {
          sync: {
            enabled: false,
            group: 1,
            suppressTooltips: false,
          },
          zoom: {
            enabled: false,
          },
          line: {
            color: "hsla( 10, 1%, 1%, 1 )",
            width: 1,
            dashPattern: [10, 3],
          },
        },
      },
    },
  };

  setupChartMetas(
    context,
    defaultVal: number | string,
    fallback: number | string,
  ) {
    const dataArray = context.dataset.data;
    const isNull = (ele) => ele === null;

    return context.dataIndex === dataArray.findIndex(isNull) - 1
      ? fallback
      : defaultVal;
  }

  mounted() {
    Chart.register(...registerables);
    this.assignChartDataSets();
    if (this.heightQuery && this.widthQuery) {
      this.pricePrognosis.options.aspectRatio = false;
    }
    this.createChart(this.canvasID, this.pricePrognosis);
    Chart.register(gradient);
    this.fetchMinMaxValues();
    this.updateTimeRangeSelection();
    this.registerCrossHairPlugin();
    //@ts-ignore
    Tooltip.positioners.custom = function (elements, eventPosition) {
      const tooltip = this;
      return {
        x: eventPosition.x,
        y: eventPosition.y,
      };
    };

    let screenshotCharts = document.getElementById("chart-box");
    screenshotCharts.style.height = this.heightQuery + "px";
    screenshotCharts.style.width = this.widthQuery + "px";
  }

  @Watch("chartDataSet")
  onChartDataSetChange() {
    this.fetchMinMaxValues();
    this.clearDataSets();
    this.assignChartDataSets();
    this.createChart(this.canvasID, this.pricePrognosis);
  }

  @Emit()
  emitTimePeriodChange(e) {
    return e.target.value;
  }

  registerCrossHairPlugin() {
    Chart.register(CrosshairPlugin);
  }

  updateTimeRangeSelection() {
    this.$store.dispatch(
      "oilprice/fetchOilAssetsPrognosis",
      this.monthsQuantity,
    );
  }

  clearDataSets() {
    this.OilChartObject.destroy();
    this.labels.splice(0, this.labels.length);
    this.intersections.splice(0, this.intersections.length);
    this.prognosisTop.splice(0, this.prognosisTop.length);
    this.prognosisBottom.splice(0, this.prognosisBottom.length);
  }

  createChart(chartId: any, chartData: any) {
    const ctx = document.getElementById(chartId) as HTMLCanvasElement;
    chartData.options.scales.x.ticks.maxTicksLimit = getMaxTickSizes(
      this.monthsQuantity,
      window.innerWidth,
    );
    this.OilChartObject = new Chart(ctx, {
      type: chartData.type,
      data: chartData.data,
      options: chartData.options,
    });
  }

  assignChartDataSets() {
    this.chartDataSet.forEach((item) => {
      this.labels.push(item.changedAtUtc);
      this.intersections.push(item.priceData);
      this.prognosisTop.push(item.pricePrognosisDataTop);
      this.prognosisBottom.push(item.pricePrognosisDataBottom);
    });
  }

  fetchMinMaxValues() {
    let prices = [];
    for (const ele of this.chartDataSet) {
      if (ele.priceData) {
        prices.push(ele.priceData);
      }
    }

    this.min = roundToNearest(Math.min(...prices), 5, false);
    this.max = roundToNearest(Math.max(...prices), 5, true);

    this.pricePrognosis.options.scales.y.max = this.max;
    this.pricePrognosis.options.scales.y.min =
      this.min - Math.round(this.min * 0.005);
  }
}
